import request from './../request/request'
import store from './../store/index'
var language = '?lang=zh-cn'
//视频获取
export function video (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/video.html' + language,params)
}
//广告获取
export function banner (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/banner.html' + language,params)
}
//合作伙伴查询
export function partner (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/partner.html' + language,params)
}
//问题查询
export function question (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/question.html' + language,params)
}
//讲者查询
export function presidium (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/presidium.html' + language,params)
}
//讲者申请
export function talker_apply (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/talker_apply.html' + language,params)
}
//观众申请
export function audience_apply (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/audience_apply.html' + language,params)
}
//联系我们提交
export function contact (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/contact.html' + language,params)
}
//短信验证码发送
export function sms (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/sms.html' + language,params)
}

//系统配置查询
export function config (params) {
    return request.post('/config.html' + language,params)
}

//委员会主题查询
export function committee (params) {
    return request.post('/committee.html' + language,params)
}
//年级查询
export function grade (params) {
    return request.post('/grade.html' + language,params)
}


//酒店查询
export function hotel (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/hotel.html' + language,params)
}
//集训营查询
export function training (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/training.html' + language,params)
}

//志愿者注册申请
export function volunteer_apply (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/volunteer_apply.html' + language,params)
}

//主席助理注册申请
export function assistant_apply (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/assistant_apply.html' + language,params)
}

//志愿者组别查询
export function vol_group (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/vol_group.html' + language,params)
}
//校园大使申请
export function amb_apply (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/amb_apply.html' + language,params)
}
//微信授权登录
export function oauthLogin (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/oauthLogin.html' + language,params)
}
//个人注册申请
export function person_apply (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/person_apply.html' + language,params, {headers : {'Authorization':localStorage.getItem('MUNtoken')}})
}
//个人注册申请详情
export function person_info (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/person_info.html' + language,params, {headers : {'Authorization':localStorage.getItem('MUNtoken')}})
}
//团队注册申请详情
export function team_info (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/team_info.html' + language,params, {headers : {'Authorization':localStorage.getItem('MUNtoken')}})
}
//团队注册申请
export function team_apply (params) {
    if(store.state.language.type == 1){
        language = '?lang=zh-cn'
    }else{
        language = '?lang=en-us'
    }
    return request.post('/team_apply.html' + language,params, {headers : {'Authorization':localStorage.getItem('MUNtoken')}})
}