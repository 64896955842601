import axios from 'axios'

const isDev = process.env.NODE_ENV === 'development'
const isTest = window.location.href.indexOf('test') > -1  ? 'https://test.betterworldmun.com' : 'https://www.betterworldmun.com'
const request = axios.create({
    baseURL:  isDev ? '' : isTest
})

// 设置拦截器

export default request